import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default function PageNotFound() {
    return (
        <Layout>
                <div>
                    <h2>404</h2>
                    <p>Vous semblez perdus !</p>
                    <Link to="/">Revenir à l'accueil</Link>
                </div>
        </Layout>
    )
}